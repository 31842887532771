import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'react-router/withRouter';
import { get, isEmpty } from 'lodash';
import loadData from './innovation_by_design_page_state_manager';

import loadable from '@loadable/component';

const NotFound = loadable(() =>
  import('../../../../components/not_found_component/not_found_component')
);
const AdContainer = loadable(() =>
  import('../../../../components/ad/AdContainer')
);
const Section = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Section
  }
);
const Image = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Image
  }
);
const Text = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Text
  }
);
const MultiTabList = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.MultiTabList
  }
);
const StickyBar = loadable(
  () => import('../../../../components/StickyBar/StickyBar'),
  {
    resolveComponent: components => components.StickyBar
  }
);
const QuickCarousel = loadable(
  () => import('../../../../components/QuickCarousel/QuickCarousel'),
  {
    resolveComponent: components => components.QuickCarousel
  }
);
const IbdLayout = loadable(
  () => import('../../../../fc-components/main/containers/IbdLayout/IbdLayout'),
  {
    resolveComponent: components => components.IbdLayout
  }
);

const IbdFuture = props => {
  const { innovationByDesign, status } = props;

  const [isMobile, setIsMobile] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [tabLists, setTabLists] = useState([]);

  const checkIfMobile = x => {
    setIsMobile(x.matches);
  };

  // Detect screen size on render, and watch for changes to it.
  useEffect(() => {
    const x = window.matchMedia('(max-width: 640px)');
    checkIfMobile(x);
    x.addListener(checkIfMobile);

    const query = window.location.search.replace('?', '').split('=');

    if (query[0] === 'view') setActiveTab(parseInt(query[1], 8));
    const fullPackageData = get(props, 'innovationByDesignPage', null);
    setTabLists(get(fullPackageData, 'acf.innovation_by_design.lists', []));
  }, []);

  useEffect(
    () => {
      if (isMobile) setActiveTab(0);
    },
    [isMobile]
  );

  const year = get(innovationByDesign, 'year', 2021);
  const fullPackageData = get(props, 'innovationByDesignPage', null);
  // setTabLists(get(fullPackageData, 'acf.innovation_by_design.lists', []));
  // const packageExcerpt = get(fullPackageData, 'excerpt.rendered', 'BAD');
  const featureImageDesktop = get(
    fullPackageData,
    'acf.franchise_images.franchise_hero_image_desktop'
  );
  const featureImageMobile = get(
    fullPackageData,
    'acf.franchise_images.franchise_hero_image_mobile'
  );
  const blurbImage = get(
    fullPackageData,
    'acf.franchise_images.franchise_blurb_image'
  );
  const franchiseBlurb = get(fullPackageData, 'acf.franchise_blurb', '');
  // const tabLists = get(fullPackageData, 'acf.innovation_by_design.lists', []);
  const carousel = get(
    fullPackageData,
    'acf.innovation_by_design.carousel',
    []
  );

  // Handle the Advertising Data
  const interstitialFreq = 7;
  const interstitial = (
    <React.Fragment>
      <AdContainer type="break" />
    </React.Fragment>
  );

  const goToTopOfList = () => {
    const distance =
      window.pageYOffset +
      document.querySelector('.tableSection').getBoundingClientRect().top;
    window.scrollTo({ top: distance - 60, left: 0, behavior: 'smooth' });
  };

  if (
    innovationByDesign.error ||
    status === 404 ||
    isEmpty(fullPackageData) ||
    year < 2012 ||
    !year
  ) {
    return (
      <section className="ibd-page">
        <article className="ibd-page__main--error-pg">
          <NotFound />
        </article>
      </section>
    );
  }

  // Perhaps in the future we'll need to manipulate the data
  // const buildListData = lists => lists;
  return (
    <IbdLayout>
      <div id="editorialLander">
        <div className="heroSection">
          <div className="desktopImage">
            <Section bgColor="#f7f7f7" maxWidth={1110}>
              <div className="desktopHeroImage">
                <Image image={featureImageDesktop} />
              </div>
              <div className="mobileHeroImage">
                <Image image={featureImageMobile} />
              </div>
            </Section>
          </div>
        </div>
        <div className="introSection">
          <Section bgColor="#f7f7f7" maxWidth={810}>
            <div className="introText">
              <Text>
                <Image image={blurbImage} width="85px" float="left" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: franchiseBlurb
                  }}
                />
              </Text>
            </div>
          </Section>
        </div>
        <div className="carouselSection">
          <Section bgColor="#f7f7f7" maxWidth={810}>
            <QuickCarousel list="ibd-2021" slides={carousel.slides} />
          </Section>
        </div>
        <div className="tableSection">
          <Section bgColor="#f7f7f7" maxWidth={1020} scrollHere noPadding>
            <MultiTabList
              list="ibd-2021"
              data={tabLists}
              interstitialUnit={interstitial}
              interstitialFreq={interstitialFreq}
              isMobile={isMobile}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </Section>
        </div>

        <StickyBar
          slug={get(fullPackageData, 'slug', 'default')}
          lists={tabLists}
          location="lander"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isMobile={isMobile}
          post={{
            id: 'innovation-by-design',
            slug: get(fullPackageData, 'acf.slug', 'ibd-2021'),
            title: get(fullPackageData, 'acf.title', 'Fast Company')
          }}
          scroll={goToTopOfList}
        />
      </div>
    </IbdLayout>
  );
};

IbdFuture.loadData = store => loadData(store);

function mapStateToProps(state = {}) {
  const ibdData = get(state, 'innovationByDesign.data', {});
  return {
    status: state.status.code,
    innovationByDesign: state.innovationByDesign,
    innovationByDesignPage: ibdData || {},
    config: state.config
  };
}

IbdFuture.propTypes = {
  innovationByDesign: PropTypes.shape({}).isRequired,
  status: PropTypes.string
};

IbdFuture.defaultProps = {
  status: undefined
};

export default withRouter(connect(mapStateToProps)(IbdFuture));
